/* NavigationBar.css */

.navbar{ 
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1000000; 
    background-color: #00507C;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  
  .nav-lists {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  
  .nav-lists li {
    display: inline;
    margin-right: 10px;
  }
  
  .nav-lists li a {
    color: #fff;
    text-decoration: none;
    padding: 5px 10px;
    border-radius: 5px;
  }
  
  .nav-lists li a:hover {
    background-color: #7abfe4;
    color: black;
    font-weight: 650;
  }
  
  .social-medias {
    display: flex;
    justify-content: space-between;
    font-size: x-large;
    
  }
  
  .social-medias a {
    color: #fff;
    text-decoration: none;
    margin-left: 20px;
  }
  
  .social-medias a:hover {
    color: #ccc;
  }
  
  .locate-icon1 :hover{
    cursor: pointer !important;
  }


  /* Media Queries for responsiveness */
  @media (max-width: 768px) {
    .navbar {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .nav-lists {
      margin: 10px 0 0 10%;
    }
  
    .social-medias {
      display: flex;
      justify-content: space-between;
      font-size: x-large;
      margin: 3% 0 0 23%;
    }
  }
  