.services {
    padding: 20px;
    text-align: center;
    background: #f5f5f5; /* or your preferred background */
  }
  
  .services h2 {
    margin-bottom: 20px;
  }
  
  .services-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px; /* or your preferred spacing */
  }
  
  .service-card {
    flex-basis: calc(21% - 20px); /* Adjust the percentage to control the card width */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 10px;
    background: #fff;
    border-radius: 4px;
    overflow: hidden;
  }
  
  .service-card:hover {
    transform: scale(1.05); /* Zoom effect on hover */
    background-color: #f0f0f0; /* Grey background on hover */
  }

  .service-card img {
    width: 100%;
    display: block;
  }
  
  .service-info {
    padding: 15px;
  }
  
  .service-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
    margin-top: 10px;
  }
  
  /* Responsive Styles */
  @media (max-width: 1200px) {
    .service-card {
      flex-basis: calc(33.333% - 20px);
    }
  }
  
  @media (max-width: 768px) {
    .service-card {
      flex-basis: calc(50% - 20px);
    }
  }
  
  @media (max-width: 480px) {
    .service-card {
      flex-basis: 100%;
    }
  }
  


  /* Add this new CSS at the end of your existing CSS code */

.row {
  display: flex;
  align-items: center; /* This ensures the content is centered vertically */
  padding: 20px;
  padding-bottom: 0px;
  background: #ffffff; /* or any other color you prefer */
}

.column {
  flex: 1; /* Each column takes equal width */
}

.image-column {
  flex: 1; /* You can adjust this value if you want a different width ratio */
  padding-right: 20px; /* Add some spacing between the image and the text */
  padding-left: 10%;
}

.content-column {
  flex: 2; /* You can adjust this value if you want a different width ratio */
  padding-left: 20px; /* Add some spacing between the text and the image */
  padding-right: 10%;
}

.service-row-image {
  width: 100%;
  height: auto;
  display: block; /* Ensures the image takes the full width of the column */
}

@media (max-width: 768px) {
  .row {
    flex-direction: column; /* Stack columns vertically on smaller screens */
  }

  .image-column, .content-column {
    padding-right: 0;
    padding-left: 0;
  }
}



.social-media-link{
  display: flex;
  justify-content: space-around;
  font-size: x-large;
  padding-top: 5%;
}

.social-media-link a {
  color: #fff;
  text-decoration: none;
  margin-left: 20px;
}

.social-media-link a:hover {
  color: #ccc;
}

.locate-icon :hover{
  cursor: pointer !important;
}


/* For Content written */

.text-content {
background: #f5f5f5 !important;
}

.text-content2 {
  background: #f5f5f5 !important;
  padding-bottom: 1%;
  margin: 1% 7% 0% 7%;
  }


.text-in-p {
  margin: 0% 7% 0% 7%;
}

.text-in-h {
  text-align: center;
    padding-top: 1%;
    margin-top: 0;
}

.text-in-h3 {
  margin: 1% 7% 0% 7%;
}


.background-div {
  /* background-image: url('../Images/BgImg.png'); */
  /* You can adjust other properties like background size, repeat, position, etc. */
  background-size: cover; /* or contain, depending on your needs */
  background-position: center; /* or any other position */
  /* Optionally, you can set a height for the div if you want the background to be visible */
  height: 300px; /* Adjust this according to your design */
}


.container2 {
  display: flex;
  justify-content: center; /* Center the items horizontally */
  flex-wrap: wrap; /* Allow items to wrap onto multiple lines */
}

.container2 > div {
  margin: 10px; /* Adjust the margin between div blocks */
  text-align: center; /* Center the content horizontally */
  flex-grow: 1; /* Allow the div blocks to grow equally */
}

.container2 img {
  max-width: 100%; /* Ensure images don't exceed container width */
  display: block; /* Remove any residual whitespace below the images */
  margin: 0 auto; /* Center the images horizontally */
}

.text2 {
  text-align: center; /* Center the text */
}

.icon-size {
  width: 120px;
  height: 120px;
}

.icon3-size {
  width: 135px;
  height: 154px;
  margin-bottom: -34px !important;
}

/* Media query for adjusting layout on desktop */
@media screen and (min-width: 768px) {
  .container2 > div {
    flex-basis: calc(25% - 20px); /* 25% width minus margin */
  }
}
