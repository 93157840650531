/* NavigationBar.css */
.footer {
  background-color: #00507C;
  padding: 10px 20px;
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center items horizontally */
}

.social-media {
  display: flex;
  justify-content: center; /* Center social media icons */
  flex-wrap: wrap; /* Wrap icons if space is needed */
  color: white;
}

.social-media a {
  color: #fff;
  text-decoration: none;
  margin: 5px; /* Give some space around icons */
}

.social-media a:hover {
  color: #ccc;
}


.copyright-text{
  color: white;
}


  /* Media Queries for responsiveness */
  @media (max-width: 768px) {
    .navbar {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .nav-list {
      margin-top: 10px;
    }
  
    .social-media {
      margin-top: 10px;
    }
  }
  

  .ch2,.ch2a,.ch3,.ch4{
    display: flex;
  }

  .ch5{
    display: flex;
    margin: 7px 0 0 14px !important;;
  }

  .ch2{
    margin: 15% 0% 0% 5%;
  }

  .ch2w{
    margin: 5% 0% 0% 0%
  }

  .ch22{
    margin: 23px 0 0 10px;
  }

  .ch-Image2{
    width: 34px !important;
    height: 32px !important;
  }

  .ch-Image3{
    width: 39px !important;
    height: 39px !important;
  }

  .ch-Image4{
    width: 47px !important;
    height: 46px !important;
    margin-top: -3px;
  }

  .ch-Image5{
    width: 40px !important;
    height: 40px !important;
  }

  @media (min-width: 768px) {
    
    .ch1{
      cursor: pointer;
      margin:2% 20% 3% -70% !important;
    }

    .ch2{
      cursor:pointer;
      margin:8% 0% 0px 8% !important;
      display: flex;
    }

    .ch2a{
      cursor:pointer;
      margin:1% 20% 0px 8% !important;
      display: flex;
    }

    .ch2w{
      cursor:pointer;
      margin:5% 0% 0px 3% !important;
      display: flex;
    }

    .ch3{
      margin:1% 20% 0px 0% !important;
    }
    .ch4{
      margin:1% 20% 0px 0% !important;
    }
    .ch5{
      width: 25%;
      margin:1% -68% 0px 0% !important;
    }

    .ch22{
      margin: 10px 0 0 10px;
    }

    .ch-Image2{
      width: 37px;
      height: 36px
    }
  }

  @media (max-width: 768px) {
    .ch-Image2{
      width: 35px !important;
      height: 35px !important;
      margin: 13% 0 0 -14px;
    }
  
    .ch-Image3{
      width: 34px !important;
      height: 34px !important;
      margin: 10% 0 0 20%;
    }
  
    .ch-Image4{
      width: 47px !important;
      height: 46px !important;
      margin: 4% 0 0 20%;
    }
  
    .ch-Image5{
      width: 33px !important;
      height: 33px !important;
      margin: 2% 4px 0 24px !important;
    }

    
  .copyright-text{
    font-size: small;
  }
  
  } 

