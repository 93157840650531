.carousel-slide {
  display: flex;
  overflow: hidden;
  transition: transform 0.5s ease; /* Add transition for smooth sliding */
}

.testimonial-item {
  flex: 1 0 100%; /* Ensure equal width for each testimonial */
}

.testimonial-item.active {
  opacity: 1;
}

.carousel-dots {
  text-align: center;
  padding: 10px;
  display: flex; /* Align dots horizontally */
  justify-content: center; /* Center dots horizontally */
}

.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 5px;
  background-color: #bbb;
  border-radius: 50%;
}

.dot.active {
  background-color: #717171;
}


.box-layout{
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}



.testimonial-author{
  text-align: right;
  font-weight: 700;
}


@media (min-width: 768px) {
  .box-layout{
    margin: 0 25% 0 25% ;
  }
  .testimonial-text{
    padding: 0 3% 0 3%;
  }
}
 