.bckSection {
    background: #f5f5f5;
}

.gradient-underline1 {
    text-align: right;
    padding-bottom: 4px;
    position: relative;
    text-decoration: none;
    margin-left: 64%;
    margin-right: 15%;
}

.gradient-underline1::after {
    background: linear-gradient(to right, transparent 10%, transparent 0%, #3F51B5 100%);
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 56%;
    margin-left: 44%;
    margin-right: 12%;
 }

 .gradient-underline2 {
    text-align: left;
    padding-bottom: 4px;
    position: relative;
    text-decoration: none;
    margin-left: 15%;
    /* margin-right: 15%; */
}

.gradient-underline2::after {
    background: linear-gradient(to left, transparent 10%, #3F51B5 100%, transparent 0%);
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 25%;
    margin-left: 0%;
    margin-right: 44%;
 }

 .gradient-underline3 {
    text-align: center;
    padding-bottom: 4px;
    position: relative;
    text-decoration: none;
    /* margin-left: 64%; */
    /* margin-right: 15%; */
}

 .gradient-underline3::after {
    background: linear-gradient(to right, transparent 0%, #3F51B5 50%, transparent 100%);
    content: "";
    position: absolute;
    bottom: 0;
    left: 37%;
    height: 2px;
    width: 25%;
  }

 @media (max-width: 768px) {

    .gradient-underline2 {
        margin-left: 9%;
    }
 
    .gradient-underline1::after {
        width: 154px !important;
        margin-left: -50% !important;
    }

    .gradient-underline2::after {
        width: 254px !important;
        margin-left: 0% !important;
    }

    .gradient-underline3::after {
        left: 23%;
    width: 55%;
      }
  }

  .gallery {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Three columns */
    grid-gap: 16px;
    /* padding: 16px; */
    /* width: 50%; */
    padding: 0 0% 0 15%;
  }
  
  .gallery-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    cursor: pointer;
    transition: transform 0.3s ease;
    display: block;
  }
  
  
    @media (max-width: 768px) {
    .gallery {
        padding: 0 15% 0 15%;
    }
    }

  @media (min-width: 768px) {
    
    .gallery-image:nth-child(1) {
        width: 52%;
        height: 64%;
        margin-left: 46%;
      }

    .gallery-image:nth-child(2) {
        width: 56%;
    height: 53%;
    }

    .gallery-image:nth-child(3) {
        width: 41%;
    height: 66%;
        margin: 0 0 0 -42%;
    }
    .gallery-image:nth-child(4) {
        width: 54%;
    height: 95%;
    margin: -25% 0 0 46%;
    }
    .gallery-image:nth-child(5) {
        width: 57%;
    height: 123%;
    MARGIN: -33% 0 0 0;
      }
      .gallery-image:nth-child(6) {
        width: 41%;
        height: 100%;
        margin: -25% 0 0 -42%;
    
      }
  }


.gallery-image:hover {
    transform: scale(1.05);
    cursor: zoom-in;
}

.lightbox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1000; /* Ensures it's above everything else */
}

.active-image {
    max-width: 90%;
    max-height: 80%;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

.arrow {
    font-size: 2em;
    color: white;
    z-index: 1010; /* Above the lightbox image */
    user-select: none; /* Prevents text selection */
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
}

.arrow.left {
    left: 10px;
}

.arrow.right {
    right: 10px;
}

  .insight-image{
    width: 77%;
    height: 88%;
    padding-left: 22%;
  }


  @media (max-width: 768px) {
    .active-image {
        position: absolute;
        top: 2%;
    }

    .insight-image{
        padding-left: 10% !important;
      }
    
}

  .container-Insight {
    display: grid; /* This defines the container as a grid container */
    grid-template-columns: 1fr 1fr; /* This creates two columns with equal width */
    gap: 10px; /* This adds some space between the columns */
    padding: 0 15% 0 15%;
}

/* Optional media query for extra small devices */
@media (max-width: 600px) {
    .container-Insight {
        grid-template-columns: 1fr; /* On small screens, stack the columns on top of each other */
    }
}

.container-Insight-text{
    justify-content: center;
    align-items: center;
    display: flex;
}