/* Slideshow.css */

.slideshow-container {
    position: relative;
    width: 100%;
    max-width: -webkit-fill-available; /* Adjust as needed */
    height: 600px; /* Adjust as needed */
    overflow: hidden;
  }
  
  .slide {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    opacity: 0;
    transition: opacity 1s, transform 1s ease-out;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .slide.active {
    opacity: 1;
    transform: scale(1);
  }
  
  .brand-logo-container {
    position: absolute;
    top: 20px;
    left: 20px;
  }
  
  .brand-logo {
    width: 20%; /* Adjust size as needed */
    height: auto; /* Maintain aspect ratio */
  }
  
  .contact-details {
    position: absolute;
    bottom: 20px;
    right: 20px;
    text-align: right;
    z-index: 1; 
  }
  
  .contact-on-images{
    color: black;
    font-weight: bold;
    font-size: x-large;
    text-decoration: none;
  }

  .contact-on-images:hover {
    text-decoration: underline !important;
    color: #1F80A6;
    cursor: pointer;
  }

  .form-Data-Fetch {
    position: absolute;
    top: 15%;
    left: 23%;
    font-family: 'cursive-font-name', cursive;
  }

  .tag1{
    color: #28A745;
    font-weight: 800;
    font-size: xxx-large;
  }

  .tag2{
    color: black;
    font-weight: 800;
    font-size: xxx-large;
  }

/* Style for the container div */
.formDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 25%;
  top: 45%;
}

/* Style for the form */
.formDetail {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inputRow {
  display: flex;
  flex-wrap: nowrap; /* Keep inputs in the same line */
}

.inputRow > * {
  margin-right: 10px; /* Adjust spacing between inputs */
}

.buttonRow {
  margin-top: 10px; /* Adjust spacing between inputs and button */
}

/* Style for form inputs */
.formDetail-input1, .formDetail-input2, .formInput-select {
  height: 50px;
  border: 1px solid grey;
  border-radius: .25rem;
  font-size: larger;
  padding-left: 25px;
  cursor: pointer;
}

.formDetail-input1 {
  width: 260px;
}

.formDetail-input2 {
  width: 320px;
}

.formInput-select {
  width: 240px;
  color: grey;
}

/* Style for the button */
.formButton {
  height: 50px;
    width: 130px;
    border: 1px solid grey;
    border-radius: .25rem;
    font-size: larger;
    /* padding-left: 25px; */
    cursor: pointer;
    margin-left: 290%;
    margin-top: 10%;
    background-color: #28A745;
}

.formButton:hover{
background: green !important;
}


@media (max-width: 1200px) {
  .formDetail-input1{
    width: 140px;
    height: 40px;
    margin-bottom: 2%;
  }

  .formDetail-input2 {
    width: 140px;
    height: 40px;
    margin-bottom: 2%;
}

.formInput-select {
  width: 170px;
  height: 44px;
  color: grey !important;
}

  .inputRow {
    display: flex;
    flex-wrap: wrap; /* Keep inputs in the same line */
  }
 
  .formButton {
    height: 30px;
    width: 80px;
    border: 1px solid grey;
    border-radius: .25rem;
    cursor: pointer;
    margin-left: 0%;
    margin-top: 0%;    
}

.formDiv{
  left: 34%;
    top: 40%;
}
}

@media screen and (max-width: 868px) {
  .tag1{
    color: #28A745;
    font-weight: 800;
    font-size: x-large;
  }

  .tag2{
    color: black;
    font-weight: 800;
    font-size: x-large;
  }

  .contact-on-images{
    color: black;
    font-weight: bold;
    font-size: medium;
    text-decoration: none;
  }

  .form-Data-Fetch {
    position: absolute;
    top: 20%;
    left: 23%;
    font-family: 'cursive-font-name', cursive;
  }
}