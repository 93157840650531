.property-card {
    display: flex;
    flex-direction: column;
  }
  
  .property-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 4px;
    margin-bottom: 16px;
  }
  
  .property-name {
    margin-bottom: 8px;
  }
  
  .property-address {
    margin-bottom: 8px;
  }
  
  .property-locality {
    margin-bottom: 16px;
  }
  
  .property-specifications {
    flex: 1;
  }


.card-container-row {
    display: flex; /* Use flexbox layout */
}

.box1, .box2 {
    flex: 1; /* Equal width for both boxes */
    border: 1px solid #000; /* Just for visualization */
    padding: 10px; /* Just for visualization */
}