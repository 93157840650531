/* ContactUs.css */

.contact-container {
    display: flex;
    flex-wrap: wrap; /* Allows items to wrap onto the next line */
    justify-content: space-between;
    padding: 20px;
    background-color: #1A3A54;
    color: white;
  }
  
  .contact-info{
    width: 100%; /* Full width on smaller screens */
    box-sizing: border-box; /* Include padding in width */
    padding-left: 2%;
  }
  .contact-form {
    width: 100%; /* Full width on smaller screens */
    box-sizing: border-box; /* Include padding in width */
  }
  
  .info-item {
    margin-bottom: 20px;
  }
  
  .contact-form h2 {
    margin-bottom: 20px;
  }
  
  .contact-form form {
    display: flex;
    flex-direction: column;
  }
  
  .contact-form form input,
  .contact-form form textarea {
    margin-bottom: 10px;
    padding: 10px;
    border: none;
    border-radius: 4px;
    width: calc(100% - 20px); /* Full width minus padding */
  }
  
  .contact-form form select{
    width: "100% !important";
    margin-bottom: 10px;
    padding: 10px;
    border: none;
    border-radius: 4px;
  }

  .contact-form form button {
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #65B16B;
    color: white;
    cursor: pointer;
    width: 100%; /* Button full width */
  }
  
  .contact-form form button:hover {
    background-color: green;
  }
  
  /* Medium screens (tablets) */
  @media (min-width: 768px) {
    .contact-info{
        padding-left: 4%;
        width: 50%; /* Split screen in half for tablets */
    }
    .contact-form {
      width: 50%; /* Split screen in half for tablets */
    }
  }
  
  /* Large screens */
  @media (min-width: 1024px) {
    .contact-container {
      flex-wrap: nowrap; /* No wrap on large screens */
    }
  
    .contact-info {
      width: 40%; /* Adjust width for larger screens */
      padding-left: 4%;
    }
  
    .contact-form {
      width: 50%; /* Adjust width for larger screens */
    }
  }
  