.property-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  height: 82vh; /* Adjust the height as needed */
  overflow-y: auto;
}

.property-card {
  border: 1px solid #ccc;
  padding: 16px;
  border-radius: 4px;
  transition: transform 0.3s;
}

.property-card:hover {
  transform: translateY(-5px);
}

@media (max-width: 768px) {
  .property-card {
    font-size: 14px;
  }
}